.register-container-first {
    padding: 20px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.fullspecs_img {
    width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

a {
    color: inherit;

}

.MuiList-root {
    height: 200px !important;
}